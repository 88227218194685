<template>
    <div>
        <label>{{message}} </label>
        <table style="text-align: right;">
            <thead>
            <tr>
                <th style="text-align: left;">When?</th>
                <th>Price</th>
                <th v-if="displayVatInformation">Vat</th>
                <th v-if="displayVatInformation">Vat Price</th>
            </tr>
            </thead>
            <tbody>
            <tr>
                <td style="text-align: left;">Now</td>
                <td>$ {{currentPrice}}</td>
                <td v-if="displayVatInformation">$ {{calculateVat(currentPrice)}}</td>
                <td v-if="displayVatInformation">$ {{calculatePriceWithVat(currentPrice)}}</td>
            </tr>
            <tr v-if="product.subscription_length_type">
                <td>Every {{product.subscription_length_type}}</td>
                <td>$ {{generalPrice}}</td>
                <td v-if="displayVatInformation">$ {{calculateVat(generalPrice)}}</td>
                <td v-if="displayVatInformation">$ {{calculatePriceWithVat(generalPrice)}}</td>
            </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
import {createNamespacedHelpers} from "vuex";

const general = createNamespacedHelpers('general');
export default {
    name: "PriceConfirmationDetails",
    props: {
        message: String,
        product: {
            type: Object,
            default: () => {}
        },
        currentPrice: Number,
        generalPrice: Number
    },
    computed: {
        ...general.mapState(['user']),
        salesTaxPercent: function () {
            return this.user.sales_tax_percent;
        },
        cardNumber: function () {
            return this.paymentMethod?.description?.split("*")?.[1] || "";
        },
        displayVatInformation: function () {
            return this.salesTaxPercent > 0;
        }
    },
    methods: {
        calculatePriceWithVat: function (price) {
            if(this.salesTaxPercent > 0) {
                return price * (this.salesTaxPercent/100 + 1);
            }
        },
        calculateVat: function (price) {
            if(this.salesTaxPercent > 0) {
                return price * (this.salesTaxPercent/100);
            }
        }
    }
}
</script>

<style scoped>

</style>
